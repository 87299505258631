'use client';

import { datadogRum } from '@datadog/browser-rum';
import { UserWithPatientAddress, UserWithWebauthn } from '@cp/database/models/users';
import { useSession } from 'next-auth/react';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { trpc } from 'trpc/client';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

type ContextProps = {
  user?: (UserWithPatientAddress & UserWithWebauthn) | null;
  numAppointments?: number;
  numScreens?: number;
  features?: Record<string, any>;
  supportedStates: string[];
  isZendeskWidgetOpen?: boolean;
  setIsZendeskWidgetOpen?: Dispatch<SetStateAction<boolean>>;
  analytics?: AnalyticsBrowser;
};

const AppContext = createContext<ContextProps>({ supportedStates: ['CA'] });

type Props = {
  children: ReactNode;
};

const AppProvider: FC<Props> = ({ children }) => {
  const { status } = useSession();
  const [isZendeskWidgetOpen, setIsZendeskWidgetOpen] = useState<boolean>(false);
  const { data } = trpc.user.get.useQuery(undefined, {
    enabled: status === 'authenticated',
  });
  const { data: features } = trpc.features.list.useQuery(undefined, {
    initialData: { features: {} },
  }) as Record<string, any>;

  const supportedStates = useMemo(
    () => (features?.acceptTexas ? ['CA', 'TX'] : ['CA']),
    [features],
  );

  const analytics = useMemo(() => {
    if (
      process.env.NEXT_PUBLIC_CUSTOMER_IO_WRITE_KEY &&
      process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID
    ) {
      return AnalyticsBrowser.load(
        {
          writeKey: process.env.NEXT_PUBLIC_CUSTOMER_IO_WRITE_KEY,
        },
        {
          integrations: {
            'Customer.io In-App Plugin': {
              siteId: process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID,
            },
          },
        },
      );
    }
  }, [process.env.NEXT_PUBLIC_CUSTOMER_IO_WRITE_KEY, process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID]);

  useEffect(() => {
    datadogRum.setUser({
      id: data?.user?.id,
    });

    if (data?.user?.patient) {
      analytics?.identify(data?.user?.patient.id, {
        email: data.user.email,
        phone: data.user.phoneNumber,
        first_name: data.user.firstName,
        last_name: data.user.lastName,
        language: data.user.patient.language,
      });
    }
  }, [data?.user?.patient, analytics]);

  return (
    <AppContext.Provider
      value={{
        user: data?.user,
        features,
        supportedStates,
        isZendeskWidgetOpen,
        setIsZendeskWidgetOpen,
        analytics,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
